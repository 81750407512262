import { postApi } from "../../api/ApiFunc";
import { LoginLink } from "../../api/link";
import { message } from "../../components/bits/PopUpMessage";

const callLogin = (val, dispatch, reducer) => {
  dispatch(
    reducer({
      loading: true,
    })
  );
  // Calculate expiration time 2 minutes from now
  const expirationTime = new Date();
  expirationTime.setDate(expirationTime.getDate() + 1);

  postApi(
    { link: LoginLink, data: val },
    (response) => {
      dispatch(
        reducer({
          loading: false,
          user: response.result,
          token: response.token,
          expirationTime: expirationTime,
        })
      );
      message("success", "Successfully Logged In");
    },
    (error) => {
      console.log(error);
      let errMsg = "There was error login in !! Please Try Again";
      let payloadMsg = error?.message ?? errMsg;
      dispatch(
        reducer({
          error: payloadMsg,
          loading: false,
          name: null,
        })
      );
      message("error", payloadMsg);
    }
  );
};

const callLogout = (dispatch, reducer, history) => {
  dispatch(
    reducer({
      user: null,
      token: null,
    })
  );
  history.push("/");
  message("success", "Successfully logged Out");
};

export { callLogin, callLogout };
