import React from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import applicationTheme from './theme/applicationTheme';

const ThemeWrapper = ({ children }) => {
  const theme = useTheme();
  const newTheme = createMuiTheme(applicationTheme(theme));

  return <MuiThemeProvider theme={newTheme}>{children}</MuiThemeProvider>;
};

export default ThemeWrapper;
