import React, { useState, useEffect, useContext } from 'react';
import set from 'lodash/set';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TextField,
  Switch,
  Checkbox,
  Select,
  FormHelperText,
  InputLabel,
  makeStyles,
  FormControlLabel,
} from '@material-ui/core';
// import Rating from '@material-ui/lab/Rating';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UploadImage from '../CustomComponents/UploadImage.js/UploadImage';
import PicturesChooser from '../CustomComponents/PictureChooser/PictureChooser';
import { isEmpty } from 'lodash';
import MuiAutoComplete from './MuiAutoComplete';

const FormContext = React.createContext(null);

export const MUIForms = ({ onSubmit, initialValues, children, ...rest }) => {
  const [values, setValues] = useState({});
  const [touched, setTouched] = useState({});
  const [error, setError] = useState({});
  const [nameList, setNameList] = useState([]);

  // useEffect(() => {
  //   if (initialValues) {
  //     setValues(initialValues);
  //   }
  // }, [initialValues]);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setValues(initialValues);
      setError({});
    }
    // else {
    //   setValues({});
    // }
  }, [initialValues]);

  // console.log(values, touched, error, 'values,touched,error');

  // useEffect(() => {
  //   const data = localStorage.getItem('formData');
  //   if (data) {
  //     setValues(JSON.parse(data));
  //   }
  // }, []);

  // useEffect(() => {
  //   localStorage.setItem('formData', JSON.stringify(values));
  // });

  const handleChange = (name, event) => {
    const target = event.target;
    const value = target
      ? target.type === 'checkbox'
        ? target.checked
        : target.value
      : event;

    const valueObject = { ...values };
    set(valueObject, name, value);
    setValues((prevValue) => ({ ...prevValue, ...valueObject }));

    const touchedObject = { ...touched };
    set(touchedObject, name, true);
    setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
  };

  const handleBlur = (name) => {
    const touchedObject = { ...touched };
    set(touchedObject, name, true);
    setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorStatusLists = [];
    nameList.forEach((element) => {
      if (get(error, element)) {
        const touchedObject = { ...touched };
        set(touchedObject, element, true);
        setTouched((prevTouched) => ({ ...prevTouched, ...touchedObject }));
        errorStatusLists.push(true);
      } else {
        errorStatusLists.push(false);
      }
    });
    if (!errorStatusLists.includes(true)) {
      onSubmit(values);
    }
  };

  return (
    <FormContext.Provider
      value={{
        values,
        touched,
        error,
        nameList,
        setError,
        setTouched,
        setNameList,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
      }}
    >
      <form onSubmit={handleSubmit} {...rest}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export const Field = ({
  name,
  component,
  validate = [],
  renderValue,
  disabled = false,
  ...rest
}) => {
  const {
    setNameList,
    values,
    error,
    setError,
    touched,
    handleBlur,
    handleChange,
  } = useContext(FormContext);

  useEffect(() => {
    setNameList((tempName) => [...tempName, name]);
    return () => {
      setNameList((tempName) => tempName.filter((i) => i !== name));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldError = [];

  validate.forEach((valid) => {
    const tempError = valid(get(values, name));
    // console.log(tempError, 'temperror');
    if (tempError) {
      fieldError.push(tempError);
    }
  });
  useEffect(() => {
    const errorObject = { ...error };
    set(errorObject, name, fieldError[0]);
    setError((prevObject) => ({ ...prevObject, ...errorObject }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(values, name)]);

  useEffect(() => {
    if (renderValue) {
      renderValue(get(values, name));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get(values, name)]);

  return (
    <>
      {component({
        meta: {
          touched: get(touched, name),
          error: fieldError[0] || get(error, name),
        },
        input: {
          name: name,
          value: get(values, name) || '',
          onBlur: (event) => handleBlur(name, event),
          onChange: (event) => handleChange(name, event),
          disabled: disabled,
        },
        ...rest,
      })}
    </>
  );
};

export const FieldArray = ({ name, children }) => {
  const { values, setValues } = useContext(FormContext);
  const push = () => {
    const tempValues = get(values, name) || [{}];
    setValues((prevValues) => ({
      ...prevValues,
      [name]: [...tempValues, {}],
    }));
  };
  const remove = (index) => {
    const tempValues = values[name];
    tempValues.splice(index, 1);
    setValues((prevValues) => ({ ...prevValues, [name]: tempValues }));
  };

  return <>{children({ fields: values[name] || [{}], push, remove })}</>;
};

export const TextFieldMUI = ({ meta: { touched, error }, input, ...rest }) => {
  return (
    <TextField
      {...input}
      {...rest}
      error={touched && Boolean(error)}
      helperText={touched && error && error}
    />
  );
};

TextFieldMUI.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

TextFieldMUI.defaultProps = {
  meta: null,
};

// /* Star */
// export const RateStarMUI = ({ meta: { touched, error }, input, ...rest }) => (
//   <Box component="fieldset" mb={3} borderColor="transparent">
//     <Rating
//       {...input}
//       {...rest}
//       error={touched && Boolean(error)}
//       helperText={touched && error && error}
//     />
//   </Box>
//   // <TextField
//   //   {...input}
//   //   {...rest}
//   //   error={touched && Boolean(error)}
//   //   helperText={touched && error && error}
//   // />
// );

// RateStarMUI.propTypes = {
//   input: PropTypes.object.isRequired,
//   meta: PropTypes.object
// };

// RateStarMUI.defaultProps = {
//   meta: null
// };

/* Select */
export const SelectMUI = ({
  meta: { touched, error },
  input,
  label,
  children,
  ...rest
}) => {
  return (
    <>
      {label && (
        <InputLabel id="attribute_group" error={touched && Boolean(error)}>
          {label}
        </InputLabel>
      )}
      <Select
        {...input}
        {...rest}
        label={label}
        error={touched && Boolean(error)}
      >
        {children}
      </Select>
      {touched && error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

SelectMUI.propTypes = {
  input: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
/* End */

/* Checkbox */
export const CheckboxMUI = ({ input, ...rest }) => {
  const { value } = input;
  return <Checkbox checked={Boolean(value)} {...input} {...rest} />;
};

CheckboxMUI.propTypes = {
  input: PropTypes.object.isRequired,
};
/* End */

/* Switch */
export const SwitchMUI = ({ input, ...rest }) => {
  const { value } = input;
  return <Switch checked={Boolean(value)} {...input} {...rest} />;
};

// SwitchMUI.propTypes = {
//   input: PropTypes.object.isRequired,
// };
/* End */

export const EditorMUI = ({ input, ...rest }) => {
  const { value, onChange } = input;

  return (
    <CKEditor
      editor={ClassicEditor}
      {...input}
      {...rest}
      data={value}
      onInit={(editor) => {}}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};

export const MuiAsyncSelect = ({ input, ...rest }) => {
  const { loadOptions, value } = rest;
  const { onChange } = input;

  return (
    <MuiAutoComplete
      value={value}
      loadOptions={loadOptions}
      onChange={onChange}
      {...input}
      {...rest}
    />
  );
};

const imageMuiStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textAlignStart: {
    textAlign: 'start',
  },
  textError: {
    color: theme.palette.error.dark,
  },
  flex: {
    display: 'flex',
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
}));
export const ImageFileMUI = ({
  meta: { touched, error },
  input,
  classes,
  multiple = false,
  showStar = false,
  star = 0,
  setStar = () => {},
  alt = false,
  desc = false,
  ...rest
}) => {
  const { value, onChange, name, disabled } = input;

  const innerClasses = imageMuiStyles();

  return (
    <div className={classNames(innerClasses.root)}>
      <>
        {!multiple && value?.length === 1 ? (
          ' '
        ) : (
          <UploadImage
            multiple={multiple}
            imageList={({ blob, file, imageDesc = null, imageAlt = null }) => {
              onChange([
                ...value,
                {
                  blob: blob,
                  file: file,
                  ...(imageDesc && { description: imageDesc }),
                  ...(imageAlt && { imageAlt: imageAlt }),
                },
              ]);
            }}
            value={value}
            name={name}
            alt={alt}
            desc={desc}
          />
        )}
        {value?.length > 0 && (
          <PicturesChooser
            value={value}
            imageList={(val) => onChange([...val])}
            edit
            showStar={showStar}
            length={value.length}
            setStarValue={(val) => setStar(val)}
            starValue={star}
            disabled={disabled}
          />
        )}
      </>

      {touched && error && (
        <span className={classNames(innerClasses.textError, innerClasses.mt1)}>
          {error}
        </span>
      )}
    </div>
  );
};

export const TextFieldWithCheckboxMUI = ({ input, label, ...rest }) => {
  const { value, onChange, disabled } = input;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(value)}
          onChange={onChange}
          disabled={disabled}
        />
      }
      label={label ? label : ''}
    />
  );
  // return <Checkbox checked={Boolean(value)} {...input} {...rest} />;
};
