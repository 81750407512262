import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle(width) {
  // const top = 50 + rand();
  // const left = 50 + rand();
  const top = 50;
  const left = 50;
  // console.log(height, width);
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
    width,
    // height
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    borderRadius: theme.spacing(1),
    backgroundColor: '#F7F7F7',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ModalComp = ({
  isOpen,
  handleClose,
  children,
  title = 'modal-title',
  description = 'modal-description',
  // height = '400px',
  width = '500px',
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle(width));

  return (
    <Modal
      aria-labelledby={title}
      aria-describedby={description}
      open={isOpen}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>
        {children}
      </div>
    </Modal>
  );
};
