import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import {
  HighlightOff,
  Done,
  WarningAmber,
  ErrorOutline,
} from "@mui/icons-material";
import "./style.css";

const GlobalMessage = ({ type, content, clearTimeOut }) => {
  return (
    <div className={`${type} message-content`}>
      <div style={{ marginRight: 10 }}>
        {type === "success" && <Done fontSize="large" />}
        {type === "warning" && <WarningAmber fontSize="large" />}
        {type === "error" && <ErrorOutline fontSize="large" />}
      </div>
      <p className="content">{content}&emsp;</p>
      <div className="close-icon" onClick={clearTimeOut}>
        <HighlightOff fontSize="large" />
      </div>
    </div>
  );
};

export const message = (type, content, timeout = 5000) => {
  const container = document.createElement("div");
  container.setAttribute("class", "component-message-wrap");
  document.body.appendChild(container);
  let setTimeOut;

  const settingTimer = () => {
    setTimeOut = setTimeout(() => {
      unmountComponentAtNode(container);
      container.parentNode.removeChild(container);
    }, timeout);
  };

  const clearingTimer = () => {
    clearTimeout(setTimeOut);
    unmountComponentAtNode(container);
    container.parentNode.removeChild(container);
  };
  render(
    <GlobalMessage
      type={type}
      content={content}
      clearTimeOut={() => clearingTimer()}
    />,
    container
  );
  settingTimer();
};
