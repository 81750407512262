import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    top: '-20%',
    right: '0px',
  },
}));

const MuiAutoComplete = ({
  loadOptions,
  value,
  onChange,
  meta,
  size = 'small',
  ...restValues
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { error, touched } = meta;

  const handleChange = (event, value) => {
    onChange(value ? value : '');
  };

  const handelInputChange = (event) => {
    setLoading(true);
    if (event?.target?.value) {
      loadOptions(event.target.value, (data) => {
        setOptions(data);
        setLoading(false);
      });
    } else {
      loadOptions('', (data) => {
        setOptions(data);
        setLoading(false);
      });
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
        setLoading(true);
        handelInputChange();
      }}
      onClose={() => {
        setOpen(false);
        setLoading(false);
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      filterOptions={(options, state) => options}
      options={options}
      loading={loading}
      onChange={handleChange}
      value={value}
      onInputChange={handelInputChange}
      classes={{ endAdornment: classes.endAdornment }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...restValues}
          error={touched && Boolean(error)}
          helperText={touched && error && error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default MuiAutoComplete;
