// import { createStore, combineReducers, applyMiddleware } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
// import { composeWithDevTools } from 'redux-devtools-extension';

// import reducers from './reducers';

// const rootReducer = combineReducers({ ...reducers });

// const middleware = [logger, thunkMiddleware];
// const middlewareEnhancer = applyMiddleware(...middleware);
// const enhancers = [middlewareEnhancer];
// const composedEnhancers = composeWithDevTools(...enhancers);

// const store = createStore(rootReducer, composedEnhancers);

// export { store };

import { applyMiddleware, createStore, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "./reducers";

const persistConfig = {
  key: "sherpachat-dashboard",
  storage: storage,
  whitelist: ["settings", "login"],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({ ...reducers })
);

const middleware = [logger, thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middleware);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

let store = createStore(persistedReducer, composedEnhancers);
let persistor = persistStore(store);

export { store, persistor };
