import login from './login/reducer';
import notification from './notification/reducer';
import settings from './settings/reducer';
import blog from './blog/reducer';
import destination from './destination/reducer';
import packageD from './packageD/reducer';
import hotel from './hotel/reducer';
import service from './service/reducer';
import language from './language/reducer';
import activity from './activity/reducer';
import attraction from './attraction/reducer';
import event from './event/reducer';
import deal from './deal/reducer';
import extra from './extras/reducer';
import users from './users/reducer';
import promotion from './promotion/reducer';
import customerSupport from './customer-support/reducer';
import car from './car/reducer';
import offlineSales from './offline-sales/reducer';
import feedback from './feedback/reducer';
import checkin from "./checkin/reducer"
export default {
  login,
  notification,
  settings,
  blog,
  destination,
  packageD: packageD,
  hotel,
  service,
  language,
  activity,
  car,
  offlineSales,
  attraction,
  event,
  deal,
  extra,
  user: users,
  promotion,
  customerSupport,
  feedback,
  checkin : checkin
};
