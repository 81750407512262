import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, Link, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import bg from '../../assest/img/bg.jpg';
import { MUIForms, Field, TextFieldMUI } from '../MUI/MUIForms';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    backgroundImage: `url(${bg})`,
    padding: '50px 0',
    fontFamily: 'Nunito',
  },
  login_text: {
    fontSize: '2rem',
    fontFamily: 'Nunito',
    color: '#000',
    marginTop: '2rem',
  },
  login_title: {
    fontSize: '3rem',
    color: '#000',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
    fontWeight: '500',
  },
  login_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  login_left: {
    width: '60%',
  },
  login_right: {
    width: '40%',
    paddingLeft: '6rem',
  },
  login_link: {
    marginTop: '8rem',
    borderTop: '1px solid #707070',
    '& a': {
      marginTop: '2rem',
      fontSize: '2rem',
      color: '#000',
      fontFamily: 'Nunito',
      display: 'block',
      fontWeight: '500',
    },
  },
  form: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 0px 6px #0A335033',
    borderRadius: '10px',
    padding: '2rem',
    marginTop: '-13rem',
  },
  form_title: {
    fontSize: '2.8rem',
    color: '#0A3350',
    textTransform: 'uppercase',
    fontFamily: 'Nunito',
    textAlign: 'center',
    fontWeight: '600',
    padding: '.5rem 0',
  },
  form_filed: {
    margin: theme.spacing(1),
    width: '100%',
    marginTop: '1rem',
    '& label': {
      color: '#000',
      fontSize: '1.6rem',
      fontFamily: 'Nunito',
    },
    '& .Mui-focused ': {
      color: '#016FD0',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#016FD0',
        borderWidth: '1px',
      },
    },
    '& .PrivateNotchedOutline-legendLabelled-30': {
      width: '8rem',
    },
  },
  form_text: {
    width: '100%',
    marginTop: '1.8rem',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    fontSize: '1.6rem',
    fontFamily: 'Nunito',
    '& .MuiOutlinedInput-input': {
      fontSize: '1.6rem',
      fontWeight: '500',
      fontFamily: 'Nunito',
    },
  },
  form_button: {
    width: '100%',
    background: '#016FD0',
    color: '#fff',
    fontSize: '1.6rem',
    marginTop: '5rem',
    fontFamily: 'Nunito',
    '&:hover': {
      background: '#094982',
    },
  },
  forgot: {
    marginTop: '2rem',
    textAlign: 'center',
    display: 'block',
    fontSize: '1.6rem',
    color: '#0A3350',
    fontFamily: 'Nunito',
    textTransform: 'uppercase',
  },
}));
function Form({ loginFunction }) {
  const classes = useStyles();
  return (
    <Box component="section" className={classes.root}>
      <Container maxWidth="md">
        <Box component="div" className={classes.login_wrapper}>
          <Box component="div" className={classes.login_left}>
            <Typography variant="h1" className={classes.login_title}>
              SherpaChat No.1 Travel booking site in nepal
            </Typography>
            <Typography conponent="p" className={classes.login_text}>
              SherpaChat.com is the leading online booking site in Nepal for
              daily hotel, flights, rental car, travel buses and other package.
              Book with us for easier, faster, and much lower rate.
            </Typography>
            <Box component="div" className={classes.login_link}>
              <Link href="#">www.sherpachat.com</Link>
            </Box>
          </Box>
          <Box component="div" className={classes.login_right}>
            <Box className={classes.form} component="div">
              <Typography variant="h3" className={classes.form_title}>
                Log in
              </Typography>
              <MUIForms
                className={classes.form_filed}
                noValidate
                autoComplete="off"
                onSubmit={(val) => loginFunction(val)}
              >
                <Field
                  name="username"
                  label="Username"
                  variant="outlined"
                  className={classes.form_text}
                  component={TextFieldMUI}
                />
                {/* <TextField
                  id="outlined-basic"
                  label="Username"
                  variant="outlined"
                  className={classes.form_text}
                  color="#000"
                /> */}
                <Field
                  name="password"
                  label="Password"
                  variant="outlined"
                  className={classes.form_text}
                  component={TextFieldMUI}
                  type="password"
                />
                {/* <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  className={classes.form_text}
                  color="#000"
                  hintText="Password"
                  floatingLabelText="Password"
                  type="password"
                /> */}
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.form_button}
                >
                  Login
                </Button>
              </MUIForms>
              <Link className={classes.forgot} href="#">
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
export default Form;
