import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Typography, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { ModalComp } from '../ModalComp/ModalComp';

const UploadImageWrapper = styled.div`
  position: relative;

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }

  .upload-btn {
    height: 122px;
    width: 122px;
    border-radius: 10px;
    border: 1px dotted #aaa;
    background: linear-gradient(#aaa, #aaa), linear-gradient(#aaa, #aaa);
    background-position: center;
    background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat: no-repeat;
  }

  .imageModalComp {
    height: 80%;
    border: 1px solid #aaa;
    border-radius: 8px;
  }
  .upload-btn-big {
    height: 300px;
    width: 100%;
  }
  .upload-btn-big img {
    width: 100%;
    height: 100%;
  }
`;

const UploadImage = ({
  name,
  required = false,
  multiple,
  value,
  imageList,
  alt,
  desc,
  popImage,
}) => {
  const [tempImageDetail, setTempImageDetail] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [imageAlt, setImageAlt] = useState('');
  const [altErr, setAltErr] = useState('');
  const [image, setImage] = useState([]);

  useEffect(() => {
    if (value) {
      setImage(value);
    }
  }, [value]);

  useEffect(() => {
    setAltErr('');
    setImageAlt('');
    setImage(value);
  }, [value]);


  const handleChange = (e) => {
    const fileList = e?.target?.files;
    const files = Object.values(fileList);
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imageList({ blob: e?.target?.result, file: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChangeWithAlt = (e) => {
    const target = e?.target;
    if (target?.type === 'file') {
      const fileList = target?.files;
      const files = Object.values(fileList);
      for (const file of files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setTempImageDetail((prevData) => ({
            ...prevData,
            blob: e.target.result,
            file: file,
            ...tempImageDetail,
          }));
        };
        reader.readAsDataURL(file);
      }
    }
  };
  if (!alt)
    return (
      <UploadImageWrapper>
        <input
          accept="image/x-png,image/jpeg"
          id={`contained-button-file-${name}`}
          type="file"
          name={name}
          required={required}
          onChange={(e) => {
            handleChange(e);
          }}
          multiple={multiple}
        />
        <div className="upload-btn" />
      </UploadImageWrapper>
    );
  return (
    <UploadImageWrapper>
      <div className="upload-btn" onClick={() => setIsOpen(true)} />
      <ModalComp
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          if (!imageAlt) {
            if (value !== image) popImage();
          }
        }}
      >
        <UploadImageWrapper>
          {!tempImageDetail?.file ? (
            <>
              <input
                type="file"
                name={name}
                id="images"
                required={required}
                onChange={(e) => {
                  handleChangeWithAlt(e);
                }}
                multiple={false}
              />
              <div className="upload-btn upload-btn-big" />
            </>
          ) : (
            <div className="upload-btn-big">
              <img src={tempImageDetail?.blob} alt="img-d" />
            </div>
          )}
        </UploadImageWrapper>
        <Typography variant="overline" display="block" gutterBottom>
          {altErr}
        </Typography>
        <br />
        <div className="align-items-center">
          {alt && (
            <div style={{ width: '100%' }}>
              <TextField
                label="Image Alt"
                value={tempImageDetail?.imageAlt || ''}
                variant="outlined"
                size="small"
                required
                onChange={(e) => {
                  e.persist();
                  setTempImageDetail((prevData) => ({
                    ...prevData,
                    imageAlt: e?.target?.value,
                  }));
                }}
                style={{ width: '100%' }}
              />
            </div>
          )}
          <br />
          {desc && (
            <>
              <div style={{ width: '100%' }}>
                <TextField
                  label="Description"
                  required
                  value={tempImageDetail?.imageDesc || ''}
                  onChange={(e) => {
                    e.persist();
                    setTempImageDetail((prevData) => ({
                      ...prevData,
                      imageDesc: e?.target?.value,
                    }));
                  }}
                  variant="outlined"
                  size="small"
                  style={{ width: '100%' }}
                />
              </div>
            </>
          )}
          <br />

          <div style={{ width: '100%' }}>
            <Button
              style={{ width: '100%' }}
              variant="outlined"
              color="primary"
              onClick={() => {
                imageList(tempImageDetail);
                setIsOpen(false);
                setTempImageDetail({});
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </ModalComp>
    </UploadImageWrapper>
  );
};

export default UploadImage;
