import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import logo from '../../assest/img/logo-dark.svg'
const useStyles = makeStyles({
    root: {
        background: '#016FD0 0% 0% no-repeat padding-box',
        padding: '20px'
    },
    banner: {
        display: 'flex',
        height: '300px',
        alignItems: 'flex-end'

    }
});
function Header() {
    const classes = useStyles();
    return (
        <Box component="section" className={classes.root}>
            <CssBaseline />
            <Container maxWidth="md">
                <Typography component="div" className={classes.banner} >
                    <img alt="Remy Sharp" src={logo} />
                </Typography>
            </Container>
        </Box>
    );
}

export default Header