import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 4),
    background: "#FFFFFF 0% 0 % no - repeat padding- box",
    border: "1px solid #016FD01A",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: "1.6rem",
      color: "#000",
      margin: "0",
      fontFamily: "Nunito",
      fontWeight: "500",
    },
    "& a": {
      color: "#016FD0",
    },
    "& nav": {
      "& a": {
        fontSize: "1.6rem",
        fontFamily: "Nunito",
        fontWeight: "500",
        marginLeft: "3rem",
      },
    },
  },
}));
function Footer() {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.root}>
      <p>
        Copyright © 2020 <Link href="#">Sherpachat</Link> . All right reserved
      </p>
      {/* <nav >
                <Link href="#">Privacy policy</Link>
                <Link href="#">Terms & conditions</Link>
            </nav> */}
    </Box>
  );
}

export default Footer;
