import React, { Suspense } from "react";
import { Switch, Route } from "react-router";
import ThemeWrapper from "./ThemeWrapper";
import Login from "../../components/pages/Login";
import { useSelector } from "react-redux";

const Application = React.lazy(() => import("./Application"));

const App = ({ messages }) => {
  const isAuthenticated = useSelector(({ login }) => login.token);
  return (
    <>
      <ThemeWrapper messages={messages}>
        <>
          <Switch>
            {isAuthenticated ? (
              <Suspense fallback={null}>
                <Route path="/" component={Application} />
              </Suspense>
            ) : (
              <Route path="/" component={Login} />
            )}
          </Switch>
        </>
      </ThemeWrapper>
    </>
  );
};

export default App;
