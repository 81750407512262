import React from 'react';
import Header from '../login/Header';
import Form from '../login/Form';
import Footer from '../login/Footer';
import { useDispatch } from 'react-redux';
import { callLogin } from '../../redux/login/apiCall';
import { loginAction } from '../../redux/login/action';
function Login() {
  const dispatch = useDispatch();

  const loginFunction = (val) => {
    callLogin(val, dispatch, loginAction);
  };
  return (
    <React.Fragment>
      <Header />
      <Form loginFunction={loginFunction} />
      <Footer />
    </React.Fragment>
  );
}

export default Login;
