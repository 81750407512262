import Axios from "axios";
import { store } from "../redux/configureStore";
import { callLogout } from "../redux/login/apiCall";
import { loginAction } from "../redux/login/action";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const API_VER = "v1";
const prodBaseUrl = (val) => `https://${val}.holidaypasal.com/api/${API_VER}/`;
const devBaseUrl = (val) => `https://dev${val}.holidaypasal.com/api/${API_VER}/`;
const testBaseurl = (port) => `http://localhost:${port}/api/${API_VER}/`;
const uatBaseUrl = (val) => `https://dev${val}.sherpachat.com/api/${API_VER}/`;

const REACT_APP_ENV = process.env.REACT_APP_ENV || "development";
const baseUrl = (val, port) => {
  if (REACT_APP_ENV === "development") {
    return devBaseUrl(val);
  } else if (REACT_APP_ENV === "local") {
    return testBaseurl(port);
  } else if (REACT_APP_ENV === "UAT") {
    return uatBaseUrl(val);
  }
  return prodBaseUrl(val);
};

export const baseURL = baseUrl("api", 5001) + "en";
export const baseURLNp = baseUrl("api", 5001) + "np";
export const accountURL = baseUrl("account", 5002) + "en";
export const checkoutEn = baseUrl("checkout", 5003) + "en";
export const checkoutNp = baseUrl("checkout", 5003) + "np";
export const carEn = baseUrl("rental", 5004) + "en";
export const carNp = baseUrl("rental", 5004) + "np";
export const packageEn = baseUrl("package", 5005) + "en";
export const packageNp = baseUrl("package", 5005) + "np";
export const activityEn = baseUrl("activity", 5006) + "en";
export const activityNp = baseUrl("activity", 5006) + "np";
export const attractionEn = baseUrl("attraction", 5007) + "en";
export const attractionNp = baseUrl("attraction", 5007) + "np";
export const dealEn = baseUrl("deal", 5008) + "en";
export const eventEn = baseUrl("event", 5009) + "en";
export const offlineSalesEn = baseUrl("offline", 5010) + "en";
export const CheckInEn = baseUrl("qr", 5011) + "en";

export const axios = Axios.create({
  baseURL,
});
// axios with header
export const authAxios = Axios.create();
// axios intercepting changes in storage
const AuthenticatedApp = () => {
  const dispatch = useDispatch(); // Using useDispatch hook
  const history = useHistory(); // Using useHistory hook

  authAxios.interceptors.request.use(
    (config) => {
      const token = store.getState()?.login?.token;
      const expirationTime = store.getState()?.login?.expirationTime;

      if (token && expirationTime && new Date().getTime() < expirationTime) {
        config.headers.Authorization = token;
      } else {
        callLogout(dispatch, loginAction, history); // Call the logout function
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export default AuthenticatedApp;
