// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const applicationTheme = (theme) => ({
  typography: {
    htmlFontSize: 10,
  },
  // primaryDark: '#01AEF3',
  // primaryLight: '#01AEF3',
  // greyColor: '#80808030',
  // palette: {
  //   primary: {
  //     main: '#01AEF3',
  //     contrastText: '#FFFFFF',
  //   },
  //   secondary: {
  //     main: '#4caf50',
  //   },
  // },
  // breakpoints: createBreakpoints({
  //   values: {
  //     xxs: 0,
  //     xs: 700,
  //     sm: 750,
  //     md: 960,
  //     slg: 1200,
  //     lg: 1280,
  //     ml: 1675,
  //     xl: 1920,
  //   },
  // }),
  // overrides: {
  //   MuiContainer: {
  //     maxWidthXl: {
  //       [theme.breakpoints.up('md')]: {
  //         paddingRight: 80,
  //         paddingLeft: 80,
  //       },
  //     },
  //   },
  //   MuiFormHelperText: {
  //     root: {
  //       color: 'red',
  //     },
  //   },
  // },
});

export default applicationTheme;
