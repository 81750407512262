import Axios from "axios";
import { store } from "../redux/configureStore";

export const initialGet = ({ link, data, auth = false }) => {
  const headers = {};
  const state = store.getState();
  const authToken = state?.core?.accessToken;

  if (auth && authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return Axios.get(link, { params: data, headers })
    .then(({ data }) => {
      return { result: data };
    })
    .catch((err) => {
      return { error: err };
    });
};

export const getApi = ({ link, data, auth = false }, onSuccess, onFail) => {
  const headers = {};
  const state = store.getState();
  const authToken = state?.login?.token;

  if (auth && authToken) {
    headers.Authorization = authToken;
  }

  Axios.get(link, { params: data, headers })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFail(err?.response?.data);
    });
};
export const postApi = (
  { link, data, params = {}, auth = false },
  onSuccess,
  onFail
) => {
  const config = { params, headers: {} };
  const state = store.getState();
  const authToken = state?.login?.token;
  if (auth && authToken) {
    config.headers.Authorization = authToken;
  }
  Axios.post(link, data, config)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFail(err?.response?.data);
    });
};
export const putApi = (
  { link, data, params = {}, auth = false },
  onSuccess,
  onFail
) => {
  const config = { params, headers: {} };
  const state = store.getState();
  const authToken = state?.login?.user?.access_token;
  if (auth && authToken) {
    config.headers.Authorization = authToken;
  }
  Axios.put(link, data, config)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFail(err?.response?.data);
    });
};
export const patchApi = (
  { link, data, params = {}, auth = false },
  onSuccess,
  onFail
) => {
  const config = { params, headers: {} };
  const state = store.getState();
  const authToken = state?.login?.token;
  if (auth && authToken) {
    config.headers.Authorization = authToken;
  }
  Axios.patch(link, data, config)
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFail(err?.response?.data);
    });
};
export const deleteApi = (
  { link, data, params = {}, auth = false },
  onSuccess,
  onFail
) => {
  const config = { params, headers: {} };
  const state = store.getState();
  const authToken = state?.login?.token;
  if (auth && authToken) {
    config.headers.Authorization = authToken;
  }

  // console.log({ ...config, data: data }, "config------->");
  Axios.delete(link, { ...config, data: data })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((err) => {
      onFail(err?.response?.data);
    });
};
