import {
  baseURL,
  accountURL,
  packageEn,
  packageNp,
  activityEn,
  activityNp,
  carEn,
  carNp,
  offlineSalesEn,
  checkoutEn,
  attractionEn,
  eventEn,
  dealEn,
  CheckInEn,
} from './Axios';

export const AdminLink = `${baseURL}/admin`;
export const LoginLink = `${accountURL}/admin/login`;

export const AdminAccountLink = `${accountURL}/admin`;
export const EmailConfigLink = `${accountURL}/emailconfigs`;


export const CheckInLink = `${CheckInEn}`;

export const HotelOwnerLink = `${accountURL}/owner`;

export const UserLink = `${accountURL}/user`;

export const BlogLink = `${accountURL}/blog`;
export const BlogCategoryLink = `${accountURL}/blog-category`;

export const PackageLink = `${packageEn}/package`;
export const HotelLink = `${baseURL}/hotel`;
export const HotelTagLink = `${baseURL}/hotel-tags`;
export const PackageTagLink = `${packageEn}/package-tag`;
export const ActivityTagLink = `${activityEn}/activity-tag`;
export const RoomLink = `${baseURL}/room`;

// Car
export const CarLink = `${carEn}/vehicle-rent`;
export const VenderLink = `${carEn}/vendor`;
export const AirportTransferLink = `${carEn}/airport-transfer`;
export const AirportLink = `${attractionEn}/airport`;

export const VehicleSpecificationLink = `${carEn}/specification`;

export const BrandLink = `${carEn}/brand`;
export const BrandNepaliLink = `${carNp}/brand`;

export const VehicleTypeLink = `${carEn}/vehicle-type`;
export const VehicleTypeNepaliLink = `${carNp}/vehicle-type`;

export const RentPlaceLink = `${carEn}/rent-place`;
export const RentPlaceNepaliLink = `${carNp}/rent-place`;

export const OfflineSalesLink = `${offlineSalesEn}/offline-sale`;
export const OnlineSalesLink = `${checkoutEn}/booking/admin-list`;
export const OnlineSalesStatisticsLink = `${checkoutEn}/booking/admin-statistics`;
export const OfflineSalesStatisticsLink = `${offlineSalesEn}/offline-sale/admin-statistics`;

export const ActivityLink = `${activityEn}/activity`;

export const ActivityDestinationLink = `${activityEn}/activity-destination`;
export const ActivityDestinationNepaliLink = `${activityNp}/activity-destination`;

export const AttractionLink = `${attractionEn}/attraction`;
export const AttractionDestinationLink = `${attractionEn}/destination`;

export const ServiceLink = `${baseURL}/service`;
export const LanguageLink = `${baseURL}/language`;

export const PackageInclusionLink = `${packageEn}/package-inclusion`;
export const PackageInclusionNepaliLink = `${packageNp}/package-inclusion`;

export const PackageDestinationLink = `${packageEn}/package-destination`;
export const PackageDestinationNepaliLink = `${packageNp}/package-destination`;

export const EventLink = `${eventEn}/event`;

export const DealLink = `${dealEn}/deal`;

export const PromotionLink = `${dealEn}/promotion`;
export const CustomerSupportLink = `${accountURL}/customer-support`;
export const FeedbackLink = `${accountURL}/feedback`;

export const TitleLink = `${baseURL}/title`;
export const FlexLink = `${dealEn}/flex`;

export const HowToPayLink = `${dealEn}/payment-procedure`;
